import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { PubSub } from 'lib/event/PubSub';
import {
  AquacomSyncStatus,
  CreateUpdateMooringSalesOrder,
  MooringCertificate,
  MooringSalesOrder,
  SiteStatus,
  categoryDimensionTypes,
} from 'models';
import { ErrorService } from 'services';
import { MooringSalesOrderService } from 'services/mooring-sales-order-service';
import { SystemFileStorageService } from 'services/system-file-storage-service';
import { ToastService } from 'services/toast-service';
import './salesorder-dialog.scss';
import { SiteService } from 'services/site-service';

@autoinject
export class SalesOrderDialog {
  protected salesOrder?: MooringSalesOrder;

  protected ready: boolean = false;
  protected form?: CreateUpdateMooringSalesOrder;

  protected isSaving = false;
  protected isLocked = false;

  protected inclueAllCertificates = false;

  protected isStandard = true;

  protected certificate: MooringCertificate = new MooringCertificate();

  protected canTransferToAquacom = false;

  protected exportProgress = false;
  protected saveProgress = false;

  constructor(
    private dialogController: DialogController,
    private errorService: ErrorService,
    private salesOrderService: MooringSalesOrderService,
    private toastService: ToastService,
    private systemFileService: SystemFileStorageService,
    private t: I18N,
    private confirmation: ConfirmDialog,
    private pubSub: PubSub,
    private siteSerivce: SiteService
  ) {}

  get zipFileText() {
    return `${this.t.tr('general.download')} zip`;
  }

  get approvalText() {
    if (this.isLocked) {
      return `${this.t.tr('general.order')} ${this.t.tr('general.approved').toLowerCase()}`;
    }
    return `${this.t.tr('general.approve')} ${this.t.tr('general.order').toLowerCase()}`;
  }

  get unlockText() {
    return `${this.t.tr('general.unlockSalesOrder')}`;
  }

  protected async approveOrder() {
    try {
      this.saveProgress = true;
      let emptyLines = 0;
      this.salesOrder.FrameOrderLines.forEach((orderLine) => {
        if (orderLine.CertificateId === null) {
          emptyLines++;
        }
      });

      const approved = await this.confirmation.confirmYesNo(
        'general.approve',
        emptyLines > 0 ? 'mooring.confirmApprovalOfSalesOrderWithEmptyLines' : 'mooring.confirmApprovalOfSalesOrder'
      );
      if (approved) {
        await this.salesOrderService.approve(this.salesOrder.Id, this.inclueAllCertificates);
        await this.getSalesOrder(this.salesOrder.Id);
      }
    } catch (error) {
      this.errorService.handleError(error);
    } finally {
      this.saveProgress = false;
    }
  }

  protected async downloadZip() {
    try {
      this.exportProgress = true;
      await this.systemFileService.download(this.salesOrder.CertificatesZipId, true);
    } catch (error) {
      this.errorService.handleError(error);
    } finally {
      this.exportProgress = false;
    }
  }

  // Aurelia lifecycle
  protected async activate(model: { salesOrderId: number }) {
    if (model?.salesOrderId) {
      await this.setup(model.salesOrderId);
    }
    this.ready = true;
  }

  protected async transferToAquacom() {
    try {
      await this.salesOrderService.transferToAquacom(this.salesOrder.Id);
      await this.getSalesOrder(this.salesOrder.Id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async getSalesOrder(id: number) {
    try {
      this.salesOrder = await this.salesOrderService.get(id);
      const filteredFramedOrderLines = this.salesOrder.FrameOrderLines.filter((x) => x.FrameOrderCategoryId != 1);
      this.salesOrder.FrameOrderLines = filteredFramedOrderLines;

      if (this.salesOrder.FrameOrderLines.length > 0) {
        this.isStandard = false;
      }
      if (this.salesOrder.ApprovedAt) {
        this.isLocked = true;
      } else {
        this.isLocked = false;
      }

      this.canTransferToAquacom =
        this.salesOrder.AquacomSyncStatus !== AquacomSyncStatus.SYNCED && Boolean(this.salesOrder.ApprovedAt);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected unlockProgress = false;
  protected async unlockSalesOrder() {
    try {
      const approve = await this.confirmation.confirmYesNo('general.unlock', 'mooring.confirmUnlockOfSalesOrder');
      if (approve) {
        this.unlockProgress = true;
        await this.salesOrderService.unlock(this.salesOrder.Id);
        await this.getSalesOrder(this.salesOrder.Id);
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.unlockProgress = false;
  }

  protected async setup(salesOrderId?: number) {
    if (salesOrderId) {
      await this.getSalesOrder(salesOrderId);
      let lastId = -1;
      this.form = {
        Id: this.salesOrder.Id,
        OrderNumber: this.salesOrder.OrderNumber,
        SiteId: this.salesOrder.SiteId,
        TypeId: this.salesOrder.TypeId,

        OrderLines: this.salesOrder?.OrderLines?.map((x) => {
          //Add dimension
          const primaryDimensionTypeId = x.PrimaryDimensionType;
          const secondaryDimensionTypeId = x.SecondaryDimensionType;
          const primaryDimensionType = categoryDimensionTypes.find((x) => x.value == primaryDimensionTypeId);
          const secondaryDimensionType = categoryDimensionTypes.find((x) => x.value == secondaryDimensionTypeId);

          let dimension = '';
          if (primaryDimensionType) {
            dimension += `${x.PrimaryDimension} ${this.t.tr(primaryDimensionType?.labelShort)}`;
          }
          if (secondaryDimensionType) {
            dimension += ` / ${x.SecondaryDimension} ${this.t.tr(secondaryDimensionType?.labelShort)}`;
          }
          return {
            Id: x.Id,
            Quantity: x.Quantity,
            Category: x.Category,
            Type: x.Type,
            ProductId: x.ProductId,
            ArticleNumber: x.ArticleNumber,
            Product: x.Product,
            PrimaryDimension: x.PrimaryDimension,
            PrimaryDimensionType: x.PrimaryDimensionType,
            SecondaryDimension: x.SecondaryDimension,
            SecondaryDimensionType: x.SecondaryDimensionType,
            Dimension: dimension,
            SalesOrderId: x.SalesOrderId,
            CertificateId: x.CertificateId,
            UnitOfMeasure: x.UnitOfMeasure,
            ArticleProductId: x.ArticleProductId,
          };
        }),
        // Sorts by category and then by id
        FrameOrderLines: this.salesOrder?.FrameOrderLines.sort((a, b) => {
          if (a.FrameOrderCategoryId !== b.FrameOrderCategoryId) {
            return a.FrameOrderCategoryId - b.FrameOrderCategoryId;
          } else {
            return a.Id - b.Id;
          }
        })?.map((x) => {
          const _isFirst = lastId !== x.FrameOrderCategoryId;
          lastId = x.FrameOrderCategoryId;

          const canEditLength =
            x.MooringArticleCategoriType === 'kjetting' || x.MooringArticleCategoriType === 'tau' ? true : false;
          let totalLength = 0;
          if (canEditLength) {
            if (x.Length && !x.Quantity) {
              totalLength = x.Length;
            } else {
              totalLength = x.Length * x.Quantity;
            }
          }

          //Add dimension
          const primaryDimensionTypeId = x.PrimaryDimensionType;
          const secondaryDimensionTypeId = x.SecondaryDimensionType;
          const primaryDimensionType = categoryDimensionTypes.find((x) => x.value == primaryDimensionTypeId);
          const secondaryDimensionType = categoryDimensionTypes.find((x) => x.value == secondaryDimensionTypeId);

          let dimension = '';
          if (primaryDimensionType) {
            dimension += `${x.PrimaryDimension} ${this.t.tr(primaryDimensionType?.labelShort)}`;
          }
          if (secondaryDimensionType) {
            dimension += ` / ${x.SecondaryDimension} ${this.t.tr(secondaryDimensionType?.labelShort)}`;
          }

          return {
            Id: x.Id,
            Length: x.Length,
            Quantity: x.Quantity,
            TotalLength: totalLength,
            ProductType: x.ProductType,
            SalesOrderId: x.SalesOrderId,
            CanEditLength: canEditLength,
            Type: x.Type,
            CertificateId: x.CertificateId,
            FrameOrderCategoryId: x.FrameOrderCategoryId,
            PrimaryDimension: x.PrimaryDimension,
            PrimaryDimensionType: x.PrimaryDimensionType,
            SecondaryDimension: x.SecondaryDimension,
            SecondaryDimensionType: x.SecondaryDimensionType,
            MooringArticleCategoriType: x.MooringArticleCategoriType,
            Dimension: dimension,
            _typeMBL: x.TypeMBL,
            _isFirst,
          };
        }),
        FrameOrderDetails: {
          Id: this.salesOrder?.FrameOrderDetails?.Id,
          CagesX: this.salesOrder?.FrameOrderDetails?.CagesX,
          CagesY: this.salesOrder?.FrameOrderDetails?.CagesY,
          CageLength: this.salesOrder?.FrameOrderDetails?.CageLength,
          CageWidth: this.salesOrder?.FrameOrderDetails?.CageWidth,
          MountedAt: this.salesOrder?.FrameOrderDetails?.MountedAt,
          ProductName: this.salesOrder?.FrameOrderDetails?.ProductName,
          ForUseIn: this.salesOrder?.FrameOrderDetails?.ForUseIn,
        },
        CustomerId: this.salesOrder.CustomerId,
        ContactId: this.salesOrder.ContactId,
        InvoiceNumber: this.salesOrder.InvoiceNumber,
      };

      this.getSiteStatus();
    }
  }

  protected siteStatus: SiteStatus;

  protected async getSiteStatus() {
    try {
      const siteId = this.form.SiteId;
      if (!siteId) return;

      const { data } = await this.siteSerivce.getSiteStatus(siteId);
      this.siteStatus = data;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onSave(data: CreateUpdateMooringSalesOrder) {
    this.isSaving = true;
    let id = this.salesOrder?.Id || data?.Id;
    data.Id = id;

    const frameOrderLines = data.FrameOrderLines;
    //Length field have to support both , and . so we have to reformat it before we send it to backend
    if (frameOrderLines && frameOrderLines.length > 0) {
      for (const frameOrderLine of frameOrderLines) {
        if (frameOrderLine.Length != null && frameOrderLine.Length.toString().includes(',')) {
          const length = frameOrderLine.Length.toString().replace(',', '.');
          frameOrderLine.Length = parseFloat(length);
        }
      }
      data.FrameOrderLines = frameOrderLines;
    }

    try {
      if (id) {
        await this.salesOrderService.update(data, id);
        this.pubSub.publish('list-entity:created', null);
      } else {
        const response = await this.salesOrderService.create(data);
        id = response.Id;
        this.pubSub.publish('list-entity:updated', null);
      }
      await this.setup(id);
      this.toastService.showSuccess('general.saved');
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.isSaving = false;
  }

  protected onCancel() {
    void this.dialogController.close(true);
  }
}

import { autoinject, bindable, observable } from 'aurelia-framework';
import { SupplierService } from 'services/supplier-service';
import {
  MooringArticleCategoryService,
  MooringArticleProductService,
  MooringArticleService,
  MooringArticleTypeService,
  MooringProductTypeService,
} from 'services';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import {
  MooringArticleCategory,
  MooringArticleProduct,
  MooringArticleType,
  MooringCertificateReliabilityClass,
  MooringProductType,
  MooringSupplier,
} from 'models';
import { CertificateFormData } from './certificate-dialog';
import { I18N } from 'aurelia-i18n';

export type CertificateMetadataFormFields = {
  SupplierId: number;
  CategoryId: number;
  TypeId: number;
  ProductTypeId: number;
} & CertificateFormData;

@autoinject()
export class CertificateMetadataForm {
  @bindable
  @observable
  model: CertificateFormData;
  modelChanged(model: CertificateFormData) {
    if (!model) return;
    this.form = {
      ...model,
      SupplierId: undefined,
      CategoryId: undefined,
      TypeId: undefined,
      ProductTypeId: undefined,
    };
  }

  @bindable onSave: (data: { data: CertificateFormData }) => void;
  @bindable onCancel: () => void;

  protected suppliers: MooringSupplier[] = [];
  protected categories: MooringArticleCategory[] = [];
  protected types: MooringArticleType[] = [];
  protected productTypes: MooringProductType[] = [];
  protected articleProducts: MooringArticleProduct[] = [];

  protected articles: {
    ArticleProductId: number;
    Name: string;
  }[] = [];

  protected mooringArticles: any[];

  protected form: CertificateMetadataFormFields = {
    InternalOrderNumber: undefined,
    Quantity: undefined,
    DateOfGoodsReceipt: new Date(),
    BatchNumberTo: undefined,
    BatchNumber: undefined,
    MooringStationId: undefined,
    ReliabilityClass: MooringCertificateReliabilityClass.II,
    SupplierId: undefined,
    CategoryId: undefined,
    TypeId: undefined,
    ProductTypeId: undefined,
    ArticleProductId: undefined,
  };

  constructor(
    private supplierService: SupplierService,
    private validationController: ValidationController,
    private validator: Validator,
    private t: I18N,
    protected mooringArticleService: MooringArticleService,
    protected mooringCategoryService: MooringArticleCategoryService,
    protected mooringTypeService: MooringArticleTypeService,
    protected mooringProductTypeService: MooringProductTypeService,
    protected mooringArticleProductService: MooringArticleProductService
  ) {}

  protected bind() {
    void this.loadOptions();
  }

  protected applyValidationRules() {
    ValidationRules.ensure('ArticleProductId')
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('SupplierId')
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('CategoryId')
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('TypeId')
      .min(1)
      .withMessage(this.t.tr('general.requiredField'))
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);
  }

  attached() {
    this.applyValidationRules();
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    const result = await this.validationController.validate({ object: this.form });
    return result.valid;
  }

  private async loadOptions() {
    const [categories, types, productTypes, suppliers, articleProducts] = await Promise.all([
      this.mooringCategoryService.getAll(),
      this.mooringTypeService.getAll(),
      this.mooringProductTypeService.getAll(),
      this.supplierService.getAll(),
      this.mooringArticleProductService.items(),
    ]);

    this.suppliers = suppliers;
    this.categories = categories;
    this.types = types;
    this.productTypes = productTypes;
    this.articleProducts = articleProducts;
    this.articles = articleProducts.map((x) => ({
      ArticleProductId: x.Id,
      Name: x.ArticleNumber + ' - ' + x.ProductName,
    }));
  }

  protected async setArticleProductId(id?: number | string) {
    if (!id) return;
    const articleId = this.articleProducts.find((x) => x.Id == id)?.MooringArticleId;
    const article = await this.mooringArticleService.get(articleId);

    this.form.ArticleProductId = +id;
    this.form.TypeId = article.TypeId;
    this.form.CategoryId = article.CategoryId;
    this.form.SupplierId = article.SupplierId;
    this.form.ProductTypeId = article.ProductTypeId;
  }

  protected async submit() {
    const isValid = await this.validate();
    if (!isValid) return;

    this.onSave({
      data: {
        SupplierId: this.form.SupplierId,
        MooringStationId: this.form.MooringStationId,
        ReliabilityClass: this.form.ReliabilityClass,
        BatchNumber: this.form.BatchNumber,
        BatchNumberTo: this.form.BatchNumberTo,
        DateOfGoodsReceipt: this.form.DateOfGoodsReceipt,
        Quantity: this.form.Quantity,
        InternalOrderNumber: this.form.InternalOrderNumber,
        ArticleProductId: this.form.ArticleProductId,
      },
    });
  }

  protected cancel() {
    this.onCancel();
  }
}

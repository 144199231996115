import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';

@autoinject
export class Preview {
  protected pdfUrl: string;

  constructor(private dialogController: DialogController) {}

  protected activate(model: { blob: Blob }) {
    if (!model.blob) {
      void this.dialogController.cancel();
    }
    const blob = new Blob([model.blob], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    this.pdfUrl = url;
  }
}

import { DialogService } from 'aurelia-dialog';
import { ErrorService } from 'services/error-service';
import { CertificateDialog } from './certificate-dialog';
import { PLATFORM, autoinject } from 'aurelia-framework';
import { PubSub } from 'lib/event/PubSub';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { RouterConfiguration } from 'aurelia-router';

@autoinject
export class CertificateView {
  protected subscriptions: Array<Subscription> = [];

  protected canCreateCertificate = false;

  constructor(
    protected pubsub: PubSub,
    protected eventAggregator: EventAggregator, // protected invoice
    private dialogService: DialogService,
    private errorService: ErrorService
  ) {
    this.subscriptions.push(
      this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
        this.canCreateCertificate = tabIndex === 0;
      })
    );
  }

  protected newCertificate() {
    try {
      void this.dialogService
        .open({
          viewModel: CertificateDialog,
        })
        .whenClosed(() => {
          document.querySelector('html').style.overflowY = null;
        });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected detatched() {
    this.subscriptions.forEach((subscription) => {
      subscription.dispose();
    });
  }

  protected configureRouter(config: RouterConfiguration) {
    return config.map([
      {
        route: [''],
        name: 'mooring-component-certificate',
        moduleId: PLATFORM.moduleName(
          '../../../features/mooring/mooring-certificate-tabs/tab-mooring-component-certificate'
        ),
        tabindex: 0,
        nav: true,
        entityShortName: 'mooring-component-certificate',
      },
      {
        route: ['frame'],
        name: 'mooring-frame-certificate',
        moduleId: PLATFORM.moduleName(
          '../../../features/mooring/mooring-certificate-tabs/tab-mooring-frame-certificate'
        ),
        tabindex: 1,
        nav: true,
        entityShortName: 'mooring-frame-certificate',
      },
    ]);
  }
}

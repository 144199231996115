import './search-input.scss';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

export type SearchResult = {
  id: number;
  title: string;
  meta: string;
  extra?: string[];
  return?: unknown;
};

export type SelectedItemDetails = {
  id: number;
  title: string;
};

type SearchHandler = ({ term }: { term: string }) => Promise<SearchResult[]>;
type GetSelectedItemDetails = ({ id }: { id: number | string }) => Promise<SelectedItemDetails>;
type OnSelectedItem = ({ item }: { item: unknown }) => void;

export type SearchBoxModel = {
  dialogTitle: string;
  searchHandler: SearchHandler;
};

@autoinject()
export class SearchInput {
  @bindable()
  public disabled = false;

  @bindable()
  public name: string;

  @bindable()
  public itemId: string | number;

  @bindable()
  public placeholder = 'general.search';

  @bindable()
  public autoFocus = false;

  @bindable()
  public onSelected: OnSelectedItem;

  @bindable()
  protected searchHandler: SearchHandler;

  @bindable()
  protected getSelectedItemDetails: GetSelectedItemDetails;

  @bindable()
  public clearable: boolean;

  protected results: SearchResult[] = [];

  protected isLoading = false;

  protected focusedSearchResultIndex = -1;

  protected selectedItem: SelectedItemDetails | null = null;

  @observable()
  protected value: number | null = null;

  @observable()
  protected searchString: string = '';

  private readonly MAX_SEARCH_RESULTS = 20;

  constructor(
    private el: Element,
    protected t: I18N
  ) {}

  attached() {
    if (!this.searchHandler) {
      throw new Error('Search input requires a search handler => search-handler.call="nameOfFunction(term)"');
    }

    if (!this.getSelectedItemDetails) {
      throw new Error(
        'Search input requires a get selected item details => get-selected-item-details.call="nameOfFunction(id)"'
      );
    }

    if (!this.onSelected) {
      throw new Error('Search input requires an on selected handler => on-selected.call="nameOfFunction(item)"');
    }

    const input: HTMLInputElement | null = this.el.querySelector('input.search-box--input');
    if (input && this.autoFocus) {
      input.focus();
    }

    if (this.itemId) {
      void this.getInitialResult(this.itemId);
    }
  }

  private async getInitialResult(id: string | number) {
    try {
      if (this.getSelectedItemDetails) {
        const selectedItem = await this.getSelectedItemDetails({ id });
        this.selectedItem = selectedItem;
      }
    } catch (error) {
      // this.errorService.handleError(error);
    }
  }

  protected async searchStringChanged(term: string) {
    try {
      if (!term) {
        this.results = [];
        return;
      }
      this.isLoading = true;

      this.results = (await this.searchHandler({ term })) || [];

      setTimeout(() => {
        this.isLoading = false;
      }, 200);
    } catch (error) {
      this.isLoading = false;
    }
  }

  protected blurSearch() {
    setTimeout(() => {
      // this.clearSearch();
    }, 200);
  }

  protected handleSearchResult(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.clearSearch();
      return true;
    }

    if (!this.results.length) return true;

    if (event.code === 'ArrowDown' && this.results.length > this.focusedSearchResultIndex + 1) {
      this.focusedSearchResultIndex++;
    }

    if (event.code === 'ArrowUp' && this.focusedSearchResultIndex > 0) {
      this.focusedSearchResultIndex--;
    }

    if (event.code === 'Enter') {
      const res = this.results[this.focusedSearchResultIndex];
      this.clearSearch();
      this.setSelectedItem(res);
    }

    return true;
  }

  protected search(event: KeyboardEvent) {
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp' || event.code === 'Enter') {
      return true;
    }
  }

  protected setFocusedSearchResult(itemIndex: number) {
    this.focusedSearchResultIndex = itemIndex;
  }

  protected clearSearch() {
    this.searchString = '';
    this.results = [];
    this.focusedSearchResultIndex = -1;
  }

  protected async valueChanged(id: number) {
    if (id) {
      this.selectedItem = await this.getSelectedItemDetails({ id });
    } else {
      this.selectedItem = null;
    }
  }

  protected setSelectedItem(item: SearchResult) {
    this.value = item.id;
    this.clearSearch();
    this.onSelected({ item: item });
  }

  protected clearSelected() {
    this.selectedItem = null;
    this.value = null;
  }
}
